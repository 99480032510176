/**
 * By default, Remix will handle hydrating your app on the client for you.
 * You are free to delete this file if you'd like to, but if you ever want it revealed again, you can run `npx remix reveal` ✨
 * For more information, see https://remix.run/file-conventions/entry.client
 */
import { RemixBrowser, useLocation, useMatches } from "@remix-run/react";
import { startTransition, StrictMode, useEffect } from "react";
import { hydrateRoot } from "react-dom/client";
import i18n from "./i18n";
import i18next from "i18next";
import { I18nextProvider, initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import Backend from "i18next-http-backend";
import { getInitialNamespaces } from "remix-i18next";
import * as Sentry from "@sentry/remix";
import { getPublicEnv } from "./helpers";
import { captureConsoleIntegration } from "@sentry/integrations";

const environment = getPublicEnv("REMIX_APP_ENVIRONMENT");

// initialize Sentry on client
if (environment && environment !== "development") {
  const replaysSessionSampleRate = environment === "production" ? 0.05 : 1;

  const sentryDSN = getPublicEnv("SENTRY_DSN");
  if (!sentryDSN) {
    throw new Error("Missing env var: SENTRY_DSN");
  } else {
    Sentry.init({
      environment,
      // this env is fetched from client side, so it uses window.ENV
      dsn: sentryDSN,
      // Set tracesSampleRate to 1.0 to capture 100%
      // of transactions for performance monitoring.
      tracesSampleRate: 0.5,
      replaysSessionSampleRate,
      replaysOnErrorSampleRate: 1,
      integrations: [
        new Sentry.BrowserTracing({
          routingInstrumentation: Sentry.remixRouterInstrumentation(useEffect, useLocation, useMatches)
        }),
        new Sentry.Replay({
          maskAllText: false,
          maskAllInputs: false,
          blockAllMedia: false
        }),
        // @ts-ignore
        captureConsoleIntegration({ levels: ["error"] })
      ]
    });
  }
}

async function hydrate() {
  await i18next
    .use(initReactI18next) // Tell i18next to use the react-i18next plugin
    .use(LanguageDetector) // Setup a client-side language detector
    .use(Backend) // Setup your backend
    .init({
      ...i18n, // spread the configuration
      // This function detects the namespaces your routes rendered while SSR use
      ns: getInitialNamespaces(),
      backend: { loadPath: "/locales/{{lng}}/{{ns}}.json" },
      detection: {
        // Here only enable 'querystring' detection, we'll detect the language only
        // server-side with remix-i18next, by using the querystring
        // we can communicate to the client the language detected server-side
        order: ["querystring", "cookie"],
        lookupQuerystring: "locale",
        caches: []
      }
    });

  startTransition(() => {
    hydrateRoot(
      document,
      <I18nextProvider i18n={i18next}>
        <StrictMode>
          <RemixBrowser />
        </StrictMode>
      </I18nextProvider>
    );
  });
}

if (window.requestIdleCallback) {
  window.requestIdleCallback(hydrate);
} else {
  // Safari doesn't support requestIdleCallback
  // https://caniuse.com/requestidlecallback
  window.setTimeout(hydrate, 1);
}
